<template>
  <!-- Hero content: will be in the middle -->
  <section class="section">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-4">
          <div class="columns is-multiline login">
            <div class="column is-12 box-auth">
              <div class="login-header">
                <h3 class="is-size-4 login-title">RESET PASSWORD</h3>
                <h3 class="is-size-7 login-subtitle">RESET YOUR PASSWORD</h3>
              </div>
              <div class="login-body">
                <form
                  @submit.prevent="processResetPassword(password, confirmation)"
                >
                  <div class="form-group">
                    <b-field>
                      <template slot="label">
                        <span class="font-auth">PASSWORD</span>
                      </template>
                      <ValidationProvider
                        vid="pConf1"
                        name="password"
                        rules="required|password:pConf2"
                        v-slot="{ errors }"
                      >
                        <b-input
                          icon="lock"
                          type="password"
                          v-model="password"
                          password-reveal
                        ></b-input>
                        <span>{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="form-group">
                    <b-field>
                      <template slot="label">
                        <span class="font-auth">PASSWORD CONFIRMATION</span>
                      </template>
                      <ValidationProvider
                        vid="pConf2"
                        name="confirm password"
                        rules="required|password:pConf1"
                        v-slot="{ errors }"
                      >
                        <b-input
                          icon="lock"
                          type="password"
                          v-model="confirmation"
                          password-reveal
                        ></b-input>
                        <span>{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <b-button
                    icon-left="lock"
                    tag="input"
                    native-type="submit"
                    type="is-primary"
                    value="Reset"
                    expanded
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Login",
  data() {
    return {
      username: this.$route.query.email,
      password: null,
      confirmation: null
    };
  },
  methods: {
    processResetPassword(password, confirmation) {
      let token = this.$route.query.token;
      if (password == null) {
        this.danger("Password is incorrect");
      }
      if (confirmation == null) {
        this.danger("Confirmation Password is incorrect");
      }
      if (password != confirmation) {
        this.danger("The password confirmation password does not match");
      } else {
        this.$store.dispatch("resetPassword", { token, password }).then(() => {
          this.success("Reset Password Success");
        });
      }
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-success"
      });
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-danger"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  border: 1px solid #d6dee3;
}
.login-header {
  border-bottom: 1px solid #f2f5f9;
  padding: 30px;
}
.login-title {
  color: #333d5d;
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: bold;
}
.login-subtitle {
  color: #b9bbc6;
}
.login-body {
  padding: 30px;
}
.form-group {
  margin-bottom: 1.7rem;
}
</style>
